/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionTypes } from '../../actions/action';
import { IExernalUserTypesRes } from './types';


const initialState: IExernalUserTypesRes = {
    items: [],
    totalCount: 0,
    optionList: [],
};

const externalUserReducers = (state: IExernalUserTypesRes = initialState, action): IExernalUserTypesRes => {
  switch (action.type) {
    case ActionTypes.EXTERNAL_USER_LIST:{
      const optionList = action.payload?.items?.map((item: any, index: any)=>{
        return{
          value: item.id,
          label: item.name,
        }
      })
      return {
        ...state,
        ...action.payload,
        optionList: optionList
      };
    }
    case ActionTypes.ADD_EXTERNAL_USER:{
      const items = [];
      items.push(action?.payload);
      state?.items?.map((item, index)=>{
        items.push(item);
      })
      const totalCount = state?.totalCount + 1;
      return {
        ...state,
        items: items,
        totalCount: totalCount,
      };
    }
    case ActionTypes.UPDATE_EXTERNAL_USER:{
      const updatedList = state?.items.map(role =>
        role.id === action.payload.id ? action.payload : role
      );
      return {
        ...state,
        items: updatedList,
      };
    }
    case ActionTypes.DELETE_EXTERNAL_USER:{
      const updatedList = state.items.filter(role => role.id !== action.payload.id);
      const totalCount = state?.totalCount - 1;
      return {
        ...state,
        items: updatedList,
        totalCount: totalCount
      };
    }
    case ActionTypes.ADMIN_LOGOUT:{
      return initialState
    }
    default:
      return state;
  }
};

export default externalUserReducers;
