/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionTypes } from './action';
import { IStatusOfDrawingRes } from "./types";


const initialState: IStatusOfDrawingRes = {
    items: [],
    totalCount: 0,
};

const statusOfDrawingReducers = (state: IStatusOfDrawingRes = initialState, action): IStatusOfDrawingRes => {
  switch (action?.type) {
    case ActionTypes.STATUS_OF_DRAWING_LIST:{
      return {
        ...state,
        ...action?.payload,
      };
    }
    case ActionTypes.ADD_STATUS_OF_DRAWING:{
      const items = [];
      items.push(action?.payload);
      state?.items?.map((item, index)=>{
        items.push(item);
      })
      const totalCount = state?.totalCount + 1;
      return {
        ...state,
        items: items,
        totalCount: totalCount,
      };
    }
    case ActionTypes.UPDATE_STATUS_OF_DRAWING:{
      const updatedList = state?.items.map(item =>
        item.id === action?.payload.id ? action?.payload : item
      );
      return {
        ...state,
        items: updatedList,
      };
    }
    case ActionTypes.DELETE_STATUS_OF_DRAWING:{
      const updatedList = state.items.filter(item => item.id !== action.payload.id);
      const totalCount = state?.totalCount - 1;
      return {
        ...state,
        items: updatedList,
        totalCount: totalCount
      };
    }
    case ActionTypes.ADMIN_LOGOUT:{
      return initialState
    }
    default:
      return state;
  }
};

export default statusOfDrawingReducers;
