/* eslint-disable array-callback-return */

import { ActionTypes } from './action';
import { ISaleOrderTypeRes } from "./types";


const initialState: ISaleOrderTypeRes = {
    optionsList: [],
    items: [],
    totalCount: 0,

};

const saleOrderReducer = (state: ISaleOrderTypeRes = initialState, action): ISaleOrderTypeRes => {
  switch (action?.type) {
    case ActionTypes.SET_STORE_SALE_ORDER_MODULE_LIST:{
      const optionsList = action?.payload?.items?.map((item, index)=>({
        ...item,
        label: item?.name,
        value: item?.id
      }));
      return {
        ...state,
          ...action?.payload,
          optionsList: optionsList,
        
      };
    }
    case ActionTypes.SET_UPDATE_STATUS:{
      const updatedList = state?.items.map(
        (item) => item.id === action.payload?.id?{...item,status: action?.payload?.status}:item
      );
      return {
        ...state,
       items:updatedList
      };
    }
    case ActionTypes.SET_DELETE_ORDER:{
      const updatedList = state.items.filter(item => item.id !== action.payload.id);
      const totalCount = state?.totalCount - 1;
      return {
        ...state,
        items: updatedList,
        totalCount: totalCount
      };
    }
    default:
      return state;
  }
};

export default saleOrderReducer;
