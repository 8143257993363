import { IDeliveryByServiceRes, IDeliveryByServiceTypes } from "./types";

export const ActionTypes = {
  DELIVERY_BY_SERVICE_LIST: "DELIVERY_BY_SERVICE_LIST",
  ADD_DELIVERY_BY_SERVICE: "ADD_DELIVERY_BY_SERVICE",
  UPDATE_DELIVERY_BY_SERVICE: "UPDATE_DELIVERY_BY_SERVICE",
  DELETE_DELIVERY_BY_SERVICE: "DELETE_DELIVERY_BY_SERVICE",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",

};

export const setDeliveryByServiceTypeList = (payload: IDeliveryByServiceRes) => ({
  type: ActionTypes.DELIVERY_BY_SERVICE_LIST,
  payload,
});

export const addDeliveryByServiceType = (payload: IDeliveryByServiceTypes) => ({
  type: ActionTypes.ADD_DELIVERY_BY_SERVICE,
  payload,
});

export const updateDeliveryByServiceType = (payload: IDeliveryByServiceTypes) => ({
  type: ActionTypes.UPDATE_DELIVERY_BY_SERVICE,
  payload,
});

export const deleteDeliveryByServiceType = (payload: IDeliveryByServiceTypes) => ({
  type: ActionTypes.DELETE_DELIVERY_BY_SERVICE,
  payload,
});