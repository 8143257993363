import { ICredentialSetupRes } from "./types";

export const ActionTypes = {
  SET_STORE_API_CREDENTIAL_SETUP_LIST: "SET_STORE_API_CREDENTIAL_SETUP_LIST",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",

};

export const setStoreCredentialList = (payload: ICredentialSetupRes) => ({
  type: ActionTypes.SET_STORE_API_CREDENTIAL_SETUP_LIST,
  payload,
});