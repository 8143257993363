
import { ActionTypes } from './action';
import { ICredentialSetupRes } from "./types";


const initialState: ICredentialSetupRes = {
  apiSetupList:{
    serializedItems: [],
    totalCount: 0,
  }
};

const credentialReducers = (state: ICredentialSetupRes = initialState, action): ICredentialSetupRes => {
  switch (action?.type) {
    case ActionTypes.SET_STORE_API_CREDENTIAL_SETUP_LIST:{
      return {
        ...state,
        apiSetupList:{
          ...action?.payload,
        },
      };
    }
    case ActionTypes.ADMIN_LOGOUT:{
      return initialState
    }
    default:
      return state;
  }
};

export default credentialReducers;
