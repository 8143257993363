import { IGradeRes,IGradeTypes } from "./types";

export const ActionTypes = {
  GRADE_LIST: "GRADE_LIST",
  ADD_GRADE: "ADD_GRADE",
  UPDATE_GRADE: "UPDATE_GRADE",
  DELETE_GRADE: "DELETE_GRADE",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",

};

export const setGradeTypeList = (payload: IGradeRes) => ({
  type: ActionTypes.GRADE_LIST,
  payload,
});

export const addGradeType = (payload: IGradeTypes) => ({
  type: ActionTypes.ADD_GRADE,
  payload,
});

export const updateGradeType = (payload: IGradeTypes) => ({
  type: ActionTypes.UPDATE_GRADE,
  payload,
});

export const deleteGradeType = (payload: IGradeTypes) => ({
  type: ActionTypes.DELETE_GRADE,
  payload,
});