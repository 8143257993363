import { ICoatingRes,ICoatingTypes } from "./types";

export const ActionTypes = {
  COATING_LIST: "COATING_LIST",
  ADD_COATING: "ADD_COATING",
  UPDATE_COATING: "UPDATE_COATING",
  DELETE_COATING: "DELETE_COATING",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",

};

export const setCoatingList = (payload: ICoatingRes) => ({
  type: ActionTypes.COATING_LIST,
  payload,
});

export const addCoating = (payload: ICoatingTypes) => ({
  type: ActionTypes.ADD_COATING,
  payload,
});

export const updateCoating = (payload: ICoatingTypes) => ({
  type: ActionTypes.UPDATE_COATING,
  payload,
});

export const deleteCoating = (payload: ICoatingTypes) => ({
  type: ActionTypes.DELETE_COATING,
  payload,
});