import { IPurchaseOrder, IPurchaseOrderTypeRes } from "./types";

export const ActionTypes = {
  SET_STORE_PURCHASE_ORDER_MODULE_LIST: "SET_STORE_PURCHASE_ORDER_MODULE_LIST",
  SET_UPDATE_STATUS:"SET_UPDATE_STATUS",
  SET_DELETE_ORDER:"SET_DELETE_ORDER",

};

export const setPurchaseOrderModuleList = (payload: IPurchaseOrderTypeRes) => ({
  type: ActionTypes.SET_STORE_PURCHASE_ORDER_MODULE_LIST,
  payload,
});
export const setPurchaseUpdateStatus = (payload: IPurchaseOrder) => ({
  type: ActionTypes.SET_UPDATE_STATUS,
  payload,
});
export const setPurchaseDeleteOrder = (payload: IPurchaseOrder) => ({
  type: ActionTypes.SET_DELETE_ORDER,
  payload,
});
