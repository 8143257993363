import { IChecklistRes,IChecklist } from "./types";

export const ActionTypes={
  CHECKLIST_QUESTION_LIST: "CHECKLIST_QUESTION_LIST",
  CHECKLIST_ADD_QUESTION: "CHECKLIST_ADD_QUESTION",
  CHECKLIST_UPDATE_QUESTION: "CHECKLIST_UPDATE_QUESTION",
  CHECKLIST_DELETE_QUESTION: "CHECKLIST_DELETE_QUESTION",
  UPDATE_STATUS_QUESTION: "UPDATE_STATUS_QUESTION",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",
};

export const setChecklistQuestionList = (payload: IChecklistRes) => ({
    type: ActionTypes.CHECKLIST_QUESTION_LIST,
    payload,
  });
  
  export const addChecklistQuestion = (payload: IChecklist) => ({
    type: ActionTypes.CHECKLIST_ADD_QUESTION,
    payload,
  });
  
  export const updateChecklistQuestion = (payload: IChecklistRes) => ({
    type: ActionTypes.CHECKLIST_UPDATE_QUESTION,
    payload,
  });
  
  export const deleteChecklistQuestion = (payload: IChecklistRes) => ({
    type: ActionTypes.CHECKLIST_DELETE_QUESTION,
    payload,
  });
  
  export const editStatusQuestion = (payload: IChecklistRes) => ({
    type: ActionTypes.UPDATE_STATUS_QUESTION,
    payload,
  });