import { IRoleRes,IRoleTypes } from "./types";

export const ActionTypes = {
    ROLE_LIST: "ROLE_LIST",
    ADD_ROLE: "ADD_ROLE",
    UPDATE_ROLE: "UPDATE_ROLE",
    DELETE_ROLE: "DELETE_ROLE",
};

export const setRoleList = (payload: IRoleRes) => ({
    type: ActionTypes.ROLE_LIST,
    payload,
  });
  
  export const addRole = (payload:IRoleTypes) => ({
    type: ActionTypes.ADD_ROLE,
    payload,
  });
  
  export const updateRole = (payload:IRoleTypes) => ({
    type: ActionTypes.UPDATE_ROLE,
    payload,
  });
  
  export const deleteRole = (payload:IRoleTypes) => ({
    type: ActionTypes.DELETE_ROLE,
    payload,
  });