import { useSelector } from "react-redux";
import { RootState } from "../store";

export const useUiReducer = () => {
    return useSelector((state: RootState) => {
      return{
        ...state.combinedReducer.uiReducer,
        formContent: state.combinedReducer.uiReducer.formContent,
        ModalPopup: state.combinedReducer.uiReducer.ModalPopup,
        requestLoader: state?.combinedReducer?.uiReducer?.requestLoader,
      }
    });
  };