/* eslint-disable @typescript-eslint/no-unused-vars */
import { IEmailTemplateRes } from "./types";

export const ActionTypes = {
  SET_STORE_EMAIL_TEMPLATE_LIST: "SET_STORE_EMAIL_TEMPLATE_LIST",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",

};

export const setStoreEmailTemplateList = (payload: IEmailTemplateRes) => ({
  type: ActionTypes.SET_STORE_EMAIL_TEMPLATE_LIST,
  payload,
});