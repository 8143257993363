/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionTypes } from "./actions";
import { IChecklistRes } from "./types";

const initialState: IChecklistRes = {
    items: [],
    totalCount: 0,
};

const ChecklistReducers = (state: IChecklistRes = initialState, action): IChecklistRes => {
  switch (action?.type) {
    case ActionTypes.CHECKLIST_QUESTION_LIST:{
      return {
        ...state,
        ...action?.payload,
      };
    }
    case ActionTypes.CHECKLIST_ADD_QUESTION:{
      const items = [];
      items.push(action?.payload);
      state?.items?.map((item, index)=>{
        items.push(item);
      })
      const totalCount = state?.totalCount + 1;
      return {
        ...state,
        items: items,
        totalCount: totalCount,
      };
    }
    case ActionTypes.CHECKLIST_UPDATE_QUESTION:{
      const updatedList = state?.items.map(item =>
        item.id === action?.payload.id ? action?.payload : item
      );
      return {
        ...state,
        items: updatedList,
      };
    }
    case ActionTypes.UPDATE_STATUS_QUESTION:{
      const updatedList = state?.items.map(item =>
        item.id === action?.payload.id ? action?.payload : item
      );
      return {
        ...state,
        items: updatedList,
      };
    }
    case ActionTypes.CHECKLIST_DELETE_QUESTION:{
      const updatedList = state.items.filter(item => item.id !== action.payload.id);
      const totalCount = state?.totalCount - 1;
      return {
        ...state,
        items: updatedList,
        totalCount: totalCount
      };
    }
    case ActionTypes.ADMIN_LOGOUT:{
      return initialState
    }
    default:
      return state;
  }
};

export default ChecklistReducers;
