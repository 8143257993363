import { ISupplierTypesRes,ISupplierTypes } from "./types";

export const ActionTypes = {
  SUPPLIER_TYPE_LIST: "SUPPLIER_TYPE_LIST",
  ADD_SUPPLIER_TYPE: "ADD_SUPPLIER_TYPE",
  UPDATE_SUPPLIER_TYPE: "UPDATE_SUPPLIER_TYPE",
  DELETE_SUPPLIER_TYPE: "DELETE_SUPPLIER_TYPE",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",

};

export const setSupplierTypeList = (payload: ISupplierTypesRes) => ({
  type: ActionTypes.SUPPLIER_TYPE_LIST,
  payload,
});

export const addSupplierType = (payload: ISupplierTypes) => ({
  type: ActionTypes.ADD_SUPPLIER_TYPE,
  payload,
});

export const updateSupplierType = (payload: ISupplierTypes) => ({
  type: ActionTypes.UPDATE_SUPPLIER_TYPE,
  payload,
});

export const deleteSupplierType = (payload: ISupplierTypes) => ({
  type: ActionTypes.DELETE_SUPPLIER_TYPE,
  payload,
});