import { ITaxRes,ITaxTypes } from "./types";

export const ActionTypes = {
  TAX_LIST: "TAX_LIST",
  ADD_TAX: "ADD_TAX",
  UPDATE_TAX: "UPDATE_TAX",
  DELETE_TAX: "DELETE_TAX",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",

};

export const setTaxList = (payload: ITaxRes) => ({
  type: ActionTypes.TAX_LIST,
  payload,
});

export const addTax = (payload: ITaxTypes) => ({
  type: ActionTypes.ADD_TAX,
  payload,
});

export const updateTax = (payload: ITaxTypes) => ({
  type: ActionTypes.UPDATE_TAX,
  payload,
});

export const deleteTax = (payload: ITaxTypes) => ({
  type: ActionTypes.DELETE_TAX,
  payload,
});