import { IStockSupplyRes } from "./types";

export const ActionTypes = {
  SET_STOCK_SUPPLY_LIST: "SET_STOCK_SUPPLY_LIST",
  SET_STOCK_SUPPLY_DETAILS:"SET_STOCK_SUPPLY_DETAILS",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",

};
export const setStockSupplyList = (payload: IStockSupplyRes) => ({
  type: ActionTypes.SET_STOCK_SUPPLY_LIST,
  payload,
});
export const setStockSupplyDetails = (payload: IStockSupplyRes) => ({
  type: ActionTypes.SET_STOCK_SUPPLY_DETAILS,
  payload,
});