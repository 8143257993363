/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionTypes } from './action';
import { IScopeOfWorkRes } from "./types";


const initialState: IScopeOfWorkRes = {
    items: [],
    totalCount: 0,
    optionList:[]
};

const scopeOfWorkReducers = (state: IScopeOfWorkRes = initialState, action): IScopeOfWorkRes => {
  switch (action?.type) {
    
    case ActionTypes.SCOPE_OF_WORK_LIST:{
      const optionList= action.payload?.items?.map(item =>{return{label:item.name, value:item.id}})

      return {
        ...state,
        ...action?.payload,
        optionList: optionList,
      };
    }
    case ActionTypes.ADD_SCOPE_OF_WORK:{
      const items = [];
      items.push(action?.payload);
      state?.items?.map((item, index)=>{
        items.push(item);
      })
      const totalCount = state?.totalCount + 1;
      return {
        ...state,
        items: items,
        totalCount: totalCount,
      };
    }
    case ActionTypes.UPDATE_SCOPE_OF_WORK:{
      const updatedList = state?.items.map(item =>
        item.id === action?.payload.id ? action?.payload : item
      );
      return {
        ...state,
        items: updatedList,
      };
    }
    case ActionTypes.DELETE_SCOPE_OF_WORK:{
      const updatedList = state.items.filter(item => item.id !== action.payload.id);
      const totalCount = state?.totalCount - 1;
      return {
        ...state,
        items: updatedList,
        totalCount: totalCount
      };
    }
    case ActionTypes.ADMIN_LOGOUT:{
      return initialState
    }
    default:
      return state;
  }
};

export default scopeOfWorkReducers;
