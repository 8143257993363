/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { initialUserData } from "../../../utils/constants/static";
import { ActionTypes } from "./action";
import { IEmailTemplateRes } from "./types";

const initialState: IEmailTemplateRes = {
  items:[],
  totalCount:0,
};

const emailTemplateReducers = (
  state: IEmailTemplateRes = initialState,
  action
): IEmailTemplateRes => {
  switch (action.type) {
    case ActionTypes.SET_STORE_EMAIL_TEMPLATE_LIST: {
      return {
        ...state,
        ...action.payload,
        items: action?.payload?.items?.map(item =>{
          return {
           ...item,
            file_json: item.file_json?JSON.parse(item?.file_json):null
          };
        })
      };
    }
    case ActionTypes.ADMIN_LOGOUT:{
      return initialState
    }
    default:
      return state;
  }
};

export default emailTemplateReducers;
