import { IUnitServiceRes, IUnitServiceTypes } from "./types";

export const ActionTypes = {
  UNIT_SERVICE_LIST: "UNIT_SERVICE_LIST",
  ADD_UNIT_SERVICE: "ADD_UNIT_SERVICE",
  UPDATE_UNIT_SERVICE: "UPDATE_UNIT_SERVICE",
  DELETE_UNIT_SERVICE: "DELETE_UNIT_SERVICE",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",

};

export const setUnitServiceList = (payload: IUnitServiceRes) => ({
  type: ActionTypes.UNIT_SERVICE_LIST,
  payload,
});

export const addUnitService = (payload: IUnitServiceTypes) => ({
  type: ActionTypes.ADD_UNIT_SERVICE,
  payload,
});

export const updateUnitService = (payload: IUnitServiceTypes) => ({
  type: ActionTypes.UPDATE_UNIT_SERVICE,
  payload,
});

export const deleteUnitService = (payload: IUnitServiceTypes) => ({
  type: ActionTypes.DELETE_UNIT_SERVICE,
  payload,
});