import { ISaleOrder, ISaleOrderTypeRes } from "./types";

export const ActionTypes = {
  SET_STORE_SALE_ORDER_MODULE_LIST: "SET_STORE_SALE_ORDER_MODULE_LIST",
  SET_UPDATE_STATUS:"SET_UPDATE_STATUS",
  SET_DELETE_ORDER:"SET_DELETE_ORDER",

};

export const setSaleOrderModuleList = (payload: ISaleOrderTypeRes) => ({
  type: ActionTypes.SET_STORE_SALE_ORDER_MODULE_LIST,
  payload,
});
export const setSaleUpdateStatus = (payload: ISaleOrder) => ({
  type: ActionTypes.SET_UPDATE_STATUS,
  payload,
});
export const setDeleteOrder = (payload: ISaleOrder) => ({
  type: ActionTypes.SET_DELETE_ORDER,
  payload,
});
export const setStoreCustomersDetails = (payload: ISaleOrderTypeRes) => ({
  type: ActionTypes.SET_UPDATE_STATUS,
  payload,
});