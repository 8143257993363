import { IProductLengthRes, IProductLengthTypes } from "./types";

export const ActionTypes = {
  PRODUCT_LENGTH_LIST: "PRODUCT_LENGTH_LIST",
  ADD_PRODUCT_LENGTH: "ADD_PRODUCT_LENGTH",
  UPDATE_PRODUCT_LENGTH: "UPDATE_PRODUCT_LENGTH",
  DELETE_PRODUCT_LENGTH: "DELETE_PRODUCT_LENGTH",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",

};

export const setProductLengthList = (payload: IProductLengthRes) => ({
  type: ActionTypes.PRODUCT_LENGTH_LIST,
  payload,
});

export const addProductLength = (payload: IProductLengthTypes) => ({
  type: ActionTypes.ADD_PRODUCT_LENGTH,
  payload,
});

export const updateProductLength = (payload: IProductLengthTypes) => ({
  type: ActionTypes.UPDATE_PRODUCT_LENGTH,
  payload,
});

export const deleteProductLength = (payload: IProductLengthTypes) => ({
  type: ActionTypes.DELETE_PRODUCT_LENGTH,
  payload,
});