import { ILocalityRes, ILocalityTypes } from "./types";

export const ActionTypes = {
  LOCALITY_LIST: "LOCALITY_LIST",
  ADD_LOCALITY: "ADD_LOCALITY",
  UPDATE_LOCALITY: "UPDATE_LOCALITY",
  DELETE_LOCALITY: "DELETE_LOCALITY",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",

};

export const setLocalityList = (payload: ILocalityRes) => ({
  type: ActionTypes.LOCALITY_LIST,
  payload,
});

export const addLocality = (payload: ILocalityTypes) => ({
  type: ActionTypes.ADD_LOCALITY,
  payload,
});

export const updateLocality = (payload: ILocalityTypes) => ({
  type: ActionTypes.UPDATE_LOCALITY,
  payload,
});

export const deleteLocality = (payload: ILocalityTypes) => ({
  type: ActionTypes.DELETE_LOCALITY,
  payload,
});