
import { ActionTypes } from './action';
import { IProposalRes } from "./types";


const initialState: IProposalRes = {

  items: [],
  totalCount: 0,
  optionList: [],
};

const proposalReducers = (state: IProposalRes = initialState, action): IProposalRes => {
  switch (action?.type) {
    case ActionTypes.SET_PROPOSAL_LIST: {
      const optionsList = action?.payload?.items?.map((item, index) => ({
        ...item,
        label: item?.name,
        value: item?.id
      }));
      return {
        ...state,
        ...action.payload,
        optionList: optionsList

      };
    }
    case ActionTypes.SET_PROPOSAL_STATUS: {
      const updatedList = state?.items.map(
        (item) => item.id === action.payload?.id?{...item,status: action?.payload?.status}:item
      );
      return {
        ...state,
       items:updatedList
      };
    }
    case ActionTypes.DELETE_PROPOSAL:{
      const updatedList = state.items.filter(item => item.id !== action.payload.id);
      const totalCount = state?.totalCount - 1;
      return {
        ...state,
        items: updatedList,
        totalCount: totalCount
      };
    }
    default:
      return state;
  }
};

export default proposalReducers;
