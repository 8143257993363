/* eslint-disable */
import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavigateFunction, useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { usePosterReducers } from "../../redux/getdata/usePostReducer";
import io from "socket.io-client";
import { setLogout, setModalLoader, setRequestLoader } from "../../redux/actions/action";
import { App_url } from "../../utils/constants/static";
import { Dispatch, UnknownAction } from "@reduxjs/toolkit";
import { handleSocketResponse } from "./webSocketResponse";
import { checkPermission, CommonResponse, formatDate2 } from "../../utils/common";
import { getData } from "../rest/fetchData";
import { setAuthData } from "../../redux/modules/user_data/action";
import { useUiReducer } from "../../redux/getdata/useUiReducer";

interface WebSocketContextType {
  socket: any | null;
  send: (data: any) => void;
  receivedMessage: any | null;
  isConnect: boolean;
}

export const WebSocketContext = createContext<WebSocketContextType | undefined>(
  undefined
);

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const {requestLoader} = useUiReducer();
  const [socket, setSocket] = useState<any | null>(null);
  const [receivedMessage, setReceivedMessage] = useState<any | null>(null);
  const [isConnect, setIsConnect] = useState<boolean>(false);
  const { user_data } = usePosterReducers();

  const dispatch:any = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const send = (data: any) => {
    
    const api_permissions = user_data?.user?.api_permissions;
    
    if(user_data?.user?.role === "admin"  || (api_permissions?.includes(`${data?.type}:${data?.action}`)) ){
      console.log("Send ::",data);
      if(data?.demo?.loader){
        dispatch(setRequestLoader(`${data?.type}:${data?.action}`))
      }
      if(data?.demo?.modal_load){
        dispatch(setModalLoader(`${data?.type}:${data?.action}`))
      }
      if (socket && socket.connected) {
        const payloadData = {
          ...data,
        }
        if(data?.payload?.query){
          payloadData.payload.query = data?.payload?.query.trim();
        }
        // if(data?.payload?.to_date){
        //   payloadData.payload.to_date = formatDate2(data?.payload?.to_date)
        // }
        if (isConnect) {
          socket.emit("action", payloadData);
        }
      } else {
        console.error("Socket.io connection not open");
      }
    }
  };

  useEffect(()=>{
    getUserDetails();
  },[user_data?.access_token])


  function isAuthPath():boolean{
    if(location.pathname===App_url.link.SIGNIN_URL&&location.pathname===App_url.link.FORGET_PASSWORD_URL&&location.pathname===App_url.link.RESET_PASSWORD_URL)
      return false;
    return true;
   
  }

  const getUserDetails = async () =>{
    if(user_data?.access_token){
      const response = await getData(App_url.link.ENDPOINT_LINKS.GET_USER_DETAILS, user_data?.access_token);
      if(response?.status === "success"){
        const permission = response?.data?.user;
        const role_permissions = response?.data?.user?.role_permissions;
        const payload = {
          ...user_data,
          user: {
            ...user_data?.user,
            ...permission,
            api_permissions: permission?.api_permissions,
            role_permissions: JSON.parse(role_permissions),
          }
        }
        dispatch(setAuthData(payload));
      }
      if(response?.status === 'fail'){
        dispatch(setLogout());
        navigate(App_url.link.SIGNIN_URL);
      }
      
    }
  }

  const connectSocket = () => {
    const socket = io(`${process.env.REACT_APP_ENDPOINT_URL}`, {
      auth: { token: user_data?.access_token },
    });

    socket.on("connect", () => {
      console.log("Socket.io connected");
      setIsConnect(true);
      setSocket(socket);
      dispatch(setRequestLoader(""));
      dispatch(setModalLoader(""))
      // dispatch(setSocketStatus(true))
    });

    socket.on("disconnect", () => {
      console.log("Socket.io disconnected");
      setIsConnect(false);
      // dispatch(setSocketStatus(false))
      setSocket(null);
      dispatch(setRequestLoader(""));
      dispatch(setModalLoader(""))
    });

    socket.on("connect_error",  (error: any) => {
      console.error("Socket.io error:", error);
      // dispatch(setSocketStatus(false))
      setIsConnect(false);
      setSocket(null);
      dispatch(setRequestLoader(""));
      dispatch(setModalLoader(""))
    });

    socket.on("data",async (data: any) => {
      console.log("Socket.io message ::", data);
      if (data?.msg === "unauthorized") {
        dispatch(setLogout());
        navigate(App_url.link.SIGNIN_URL);
      }
      if (data?.status === false) {
        CommonResponse(data);
      }
      
      await dispatch(await handleSocketResponse("data", data, dispatch, send, navigate, user_data));
      
    });

    return socket;
  };

  useEffect(() => {
    let socket: any | null = null;

    if(user_data?.is_Login && isAuthPath()) {
      socket = connectSocket();
      const reconnectInterval = setInterval(() => {
        
        if (user_data?.access_token) {
          if (!socket || !socket.connected) {
            socket = connectSocket();
            dispatch(setRequestLoader(""));
          }
        } else {
          dispatch(setLogout());
          navigate(App_url.link.INITIAL_URL);
        }
      }, 5000);

      return () => {
        clearInterval(reconnectInterval);
        if (socket) {
          socket.disconnect();
        }
      };
    }
  }, [user_data?.access_token, dispatch]);

  const contextValue: WebSocketContextType = {
    socket: socket,
    send: send,
    receivedMessage: receivedMessage,
    isConnect: isConnect,
  };

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
};
