import { IScopeOfProjectRes,IScopeOfProjectTypes } from "./types";

export const ActionTypes = {
  SCOPE_OF_PROJECT_LIST: "SCOPE_OF_PROJECT_LIST",
  ADD_SCOPE_OF_PROJECT: "ADD_SCOPE_OF_PROJECT",
  UPDATE_SCOPE_OF_PROJECT: "UPDATE_SCOPE_OF_PROJECT",
  DELETE_SCOPE_OF_PROJECT: "DELETE_SCOPE_OF_PROJECT",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",

};

export const setScopeOfProjectList = (payload: IScopeOfProjectRes) => ({
  type: ActionTypes.SCOPE_OF_PROJECT_LIST,
  payload,
});

export const addScopeOfProject = (payload: IScopeOfProjectTypes) => ({
  type: ActionTypes.ADD_SCOPE_OF_PROJECT,
  payload,
});

export const updateScopeOfProject = (payload: IScopeOfProjectTypes) => ({
  type: ActionTypes.UPDATE_SCOPE_OF_PROJECT,
  payload,
});

export const deleteScopeOfProject = (payload: IScopeOfProjectTypes) => ({
  type: ActionTypes.DELETE_SCOPE_OF_PROJECT,
  payload,
});