
import { ActionTypes } from './action';
import { ILogisticsDataRes } from "./types";


const initialState: ILogisticsDataRes = {
  items: [],
  totalCount: 0,
  optionList: [],
};

const logisticReducer = (state: ILogisticsDataRes = initialState, action): ILogisticsDataRes => {
  switch (action?.type) {
    case ActionTypes.SET_LOGISTIC_LIST: {
      const optionsList = action?.payload?.items?.map((item, index) => ({
        ...item,
        label: item?.name,
        value: item?.id
      }));
      return {
        ...state,
        ...action.payload,
        optionList: optionsList

      };
    }
    default:
      return state;
  }
};

export default logisticReducer;
