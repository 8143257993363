/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionTypes } from './action';
import { ISupplierTypesRes } from "./types";


const initialState: ISupplierTypesRes = {
    items: [],
    totalCount: 0,
    // optionList:[]
};

const supplierTypeReducers = (state: ISupplierTypesRes = initialState, action): ISupplierTypesRes => {
  switch (action?.type) {
    case ActionTypes.SUPPLIER_TYPE_LIST:{
      const optionList = action?.payload?.items?.map((item: any, index: any)=>{
        return{
          value: item.id,
          label: item.name,
        }
      })
      return {
        ...state,
        ...action?.payload,
        optionList: optionList
      };
    }
    case ActionTypes.ADD_SUPPLIER_TYPE:{
      const items = [];
      items.push(action?.payload);
      state?.items?.map((item, index)=>{
        items.push(item);
      })
      const totalCount = state?.totalCount + 1;
      return {
        ...state,
        items: items,
        totalCount: totalCount,
      };
    }
    case ActionTypes.UPDATE_SUPPLIER_TYPE:{
      const updatedList = state?.items.map(item =>
        item.id === action?.payload.id ? action?.payload : item
      );
      return {
        ...state,
        items: updatedList,
      };
    }
    case ActionTypes.DELETE_SUPPLIER_TYPE:{
      const updatedList = state.items.filter(item => item.id !== action.payload.id);
      const totalCount = state?.totalCount - 1;
      return {
        ...state,
        items: updatedList,
        totalCount: totalCount
      };
    }
    case ActionTypes.ADMIN_LOGOUT:{
      return initialState
    }
    default:
      return state;
  }
};

export default supplierTypeReducers;
