
import { ActionTypes } from './action';
import { IFabricationRes } from "./types";


const initialState: IFabricationRes = {
  fabricationList:{
    items: [],
    totalCount: 0,
    optionList: [],
  },
  fabricationScheduleList:{
    items: [],
    totalCount: 0,
    optionList: [],
  },
  fabricationDeliveredList:{
    items: [],
    totalCount: 0,
    optionList: [],
  },
  fabricationDetails: null,
};

const fabricationReducers = (state: IFabricationRes = initialState, action): IFabricationRes => {
  switch (action?.type) {
    case ActionTypes.SET_STORE_FABRICATION_LIST:{
      const optionsList = action?.payload?.items?.map((item, index)=>({
        ...item,
        label: item?.name,
        value: item?.id
      }));
      return {
        ...state,
        fabricationList:{
          ...action.payload,
          optionList: optionsList
        }
      };
    }
    case ActionTypes.SET_STORE_FABRICATION_SCHEDULE_LIST:{
      const optionsList = action?.payload?.items?.map((item, index)=>({
        ...item,
        label: item?.name,
        value: item?.id
      }));
      return {
        ...state,
        fabricationScheduleList:{
          ...action.payload,
          optionList: optionsList
        }
      };
    }
    case ActionTypes.SET_STORE_FABRICATION_DELIVERED_LIST:{
      const optionsList = action?.payload?.items?.map((item, index)=>({
        ...item,
        label: item?.name,
        value: item?.id
      }));
      return {
        ...state,
        fabricationDeliveredList:{
          ...action.payload,
          optionList: optionsList
        }
      };
    }
    case ActionTypes?.SET_STORE_FABRICATION_DETAILS:{
      return {
        ...state,
        fabricationDetails:action.payload
      };
    }
    default:
      return state;
  }
};

export default fabricationReducers;
