
import { ActionTypes } from './action';
import { IEmailSetupRes } from "./types";


const initialState: IEmailSetupRes = {
  emailSetupList:{
    optionsList:[],
    serializedItems: [],
    totalCount: 0,
  }
};

const emailSetupReducers = (state: IEmailSetupRes = initialState, action): IEmailSetupRes => {
  switch (action?.type) {
    case ActionTypes.SET_STORE_EMAIL_SETUP_LIST:{
      const optionsList = action?.payload?.serializedItems?.map((item, index)=>({...item, value: item?.name, label: item?.name}));
      return {
        ...state,
        emailSetupList:{
          ...action?.payload,
          optionsList: optionsList
        },
      };
    }
    case ActionTypes.ADMIN_LOGOUT:{
      return initialState
    }
    default:
      return state;
  }
};

export default emailSetupReducers;
