import { IProposalEmailType, IProposalListTypes, IProposalRes } from "./types";

export const ActionTypes = {
  SET_PROPOSAL_LIST: "SET_PROPOSAL_LIST",
  SET_PROPOSAL_DETAILS:"SET_PROPOSAL_DETAILS",
  UPDATE_PROPOSAL: "UPDATE_PROPOSAL",
  DELETE_PROPOSAL: "DELETE_PROPOSAL",
  SENT_PROPOSAL: "SENT_PROPOSAL",
SET_PROPOSAL_STATUS: "SET_PROPOSAL_STATUS"

};
export const setProposalList = (payload: IProposalRes) => ({
  type: ActionTypes.SET_PROPOSAL_LIST,
  payload,
});
export const setProposalDetails = (payload: IProposalListTypes) => ({
  type: ActionTypes.SET_PROPOSAL_DETAILS,
  payload,
});

export const setProposalStatus = (payload: IProposalListTypes) => ({
  type: ActionTypes.SET_PROPOSAL_STATUS,
  payload,
});

export const updateProposal = (payload: IProposalListTypes) => ({
  type: ActionTypes.UPDATE_PROPOSAL,
  payload,
});

export const deleteProposal = (payload: IProposalListTypes) => ({
  type: ActionTypes.DELETE_PROPOSAL,
  payload,
});

export const sentProposal = (payload: IProposalEmailType) => ({
  type: ActionTypes.SENT_PROPOSAL,
  payload,
});
