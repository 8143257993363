/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionTypes } from './action';
import { ILocalityRes } from "./types";


const initialState: ILocalityRes = {
    items: [],
    totalCount: 0,
    optionList: []
};

const localityReducers = (state: ILocalityRes = initialState, action): ILocalityRes => {
  switch (action?.type) {
    case ActionTypes.LOCALITY_LIST:{
      const optionList = action?.payload?.items?.map((item: any, index: any)=>{
        return{
          value: item.id,
          label: item.name,
        }
      })
      return {
        ...state,
        ...action?.payload,
        optionList: optionList,
      };

    }
    case ActionTypes.ADD_LOCALITY:{
      const items = [];
      items.push(action?.payload);
      state?.items?.map((item, index)=>{
        items.push(item);
      })
      const totalCount = state?.totalCount + 1;
      return {
        ...state,
        items: items,
        totalCount: totalCount,
      };
    }
    case ActionTypes.UPDATE_LOCALITY:{
      const updatedList = state?.items.map(locality =>
        locality.id === action?.payload.id ? action?.payload : locality
      );
      return {
        ...state,
        items: updatedList,
      };
    }
    case ActionTypes.DELETE_LOCALITY:{
      const updatedList = state.items.filter(role => role.id !== action.payload.id);
      const totalCount = state?.totalCount - 1;
      return {
        ...state,
        items: updatedList,
        totalCount: totalCount
      };
    }
    case ActionTypes.ADMIN_LOGOUT:{
      return initialState
    }
    default:
      return state;
  }
};

export default localityReducers;
