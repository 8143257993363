
export const ActionTypes = {
    LOGISTIC_LOCATION_SERVICE_LIST: "LOGISTIC_LOCATION_SERVICE_LIST",
    ADMIN_LOGOUT: "ADMIN_LOGOUT",
};

export const setLogisticLocationServiceTypeList = (payload: { data: any[] }) => ({
    type: ActionTypes.LOGISTIC_LOCATION_SERVICE_LIST,
    payload
});
