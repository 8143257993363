
import { ActionTypes } from './action';
import { IShopRes } from "./types";


const initialState: IShopRes = {
  shopList:{
    items: [],
    totalCount: 0,
    optionList: [],
  },
  shopScheduleList:{
    items: [],
    totalCount: 0,
    optionList: [],
  },
  shopDeliveredList:{
    items: [],
    totalCount: 0,
    optionList: [],
  },
  shopDetails: null,
};

const shopReducers = (state: IShopRes = initialState, action): IShopRes => {
  switch (action?.type) {
    case ActionTypes.SET_STORE_SHOP_LIST:{
      const optionsList = action?.payload?.items?.map((item, index)=>({
        ...item,
        label: item?.name,
        value: item?.id
      }));
      return {
        ...state,
        shopList:{
          ...action.payload,
          optionList: optionsList
        }
      };
    }
    case ActionTypes.SET_STORE_SHOP_SCHEDULE_LIST:{
 
      const optionsList = action?.payload?.items?.map((item, index)=>({
        ...item,
        label: item?.name,
        value: item?.id
      }));
      return {
        ...state,
        shopScheduleList:{
          ...action.payload,
          optionList: optionsList
        }
      };
    }
    case ActionTypes.SET_STORE_SHOP_DELIVERED_LIST:{
      const optionsList = action?.payload?.items?.map((item, index)=>({
        ...item,
        label: item?.name,
        value: item?.id
      }));
      return {
        ...state,
        shopDeliveredList:{
          ...action.payload,
          optionList: optionsList
        }
      };
    }
    case ActionTypes?.SET_STORE_SHOP_DETAILS:{
      return {
        ...state,
        shopDetails:action.payload
      };
    }
    case ActionTypes.DELETE_SHOP_SUPPLY_ORDER: {
    
      
      const updatedList = state?.shopScheduleList?.items.filter(
        (item) => item.id !== action.payload
      );     
      console.log("updatedList::",action.payload);

      const totalCount = state?.shopScheduleList?.totalCount - 1;
      return {
        ...state,
        shopScheduleList: {
          items: updatedList,
          optionList:[],
          totalCount: totalCount,
        },
      }
    }
    case ActionTypes.UPDATE_SHOP_SUPPLY_LIST: {
    
      
      const updatedList = state?.shopDeliveredList?.items?.map(
        (item) => item.id === action.payload?.id ? action?.payload:item
      );    
      console.log("updatedList::",action.payload);
      return {
        ...state,
        shopDeliveredList: {
          items: updatedList,
          optionList:[],
          totalCount: state?.shopDeliveredList?.totalCount,
        },
      }
    }
    default:
      return state;
  }
};

export default shopReducers;
