/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionTypes } from "./action";
import { IStockSupplyOrdersRes, IStockSupplyRes } from "./types";

const initialState: IStockSupplyOrdersRes = {
  items: [],
  totalCount: 0,
  optionList: [],
};

const stockSupplyReducers = (
  state: IStockSupplyOrdersRes = initialState,
  action
): IStockSupplyOrdersRes => {
  switch (action?.type) {
    case ActionTypes.SET_STOCK_SUPPLY_LIST: {
      const optionsList = action?.payload?.items?.map((item, index) => ({
        ...item,
        label: item?.name,
        value: item?.id,
      }));
      return {
        ...state,
        ...action.payload,
        optionList: optionsList,
      };
    }

    default:
      return state;
  }
};

export default stockSupplyReducers;
