import { IAddFabrication, IFabricationOrdersRes } from "./types";

export const ActionTypes = {
  SET_STORE_FABRICATION_LIST: "SET_STORE_FABRICATION_LIST",
  SET_STORE_FABRICATION_DETAILS:"SET_STORE_FABRICATION_DETAILS",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",
  SET_STORE_FABRICATION_SCHEDULE_LIST:"SET_STORE_FABRICATION_SCHEDULE_LIST",
  SET_STORE_FABRICATION_DELIVERED_LIST:"SET_STORE_FABRICATION_DELIVERED_LIST",
};

export const setStoreFabricationList = (payload: IFabricationOrdersRes) => ({
  type: ActionTypes.SET_STORE_FABRICATION_LIST,
  payload,
});
export const setStoreFabricationDetails = (payload: IAddFabrication) => ({
  type: ActionTypes.SET_STORE_FABRICATION_DETAILS,
  payload,
});
export const setStoreFabricationScheduleList = (payload: IFabricationOrdersRes) => ({
  type: ActionTypes.SET_STORE_FABRICATION_SCHEDULE_LIST,
  payload,
});
export const setStoreFabricationDeliveredList = (payload: IFabricationOrdersRes) => ({
  type: ActionTypes.SET_STORE_FABRICATION_DELIVERED_LIST,
  payload,
});