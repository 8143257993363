import { IProductRes,IProductTypes } from "./types";

export const ActionTypes = {
  PRODUCT_LIST: "PRODUCT_LIST",
  ADD_PRODUCT: "ADD_PRODUCT",
  UPDATE_PRODUCT: "UPDATE_PRODUCT",
  DELETE_PRODUCT: "DELETE_PRODUCT",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",

};

export const setProductTypeList = (payload: IProductRes) => ({
  type: ActionTypes.PRODUCT_LIST,
  payload,
});

export const addProductType = (payload: IProductTypes) => ({
  type: ActionTypes.ADD_PRODUCT,
  payload,
});

export const updateProductType = (payload: IProductTypes) => ({
  type: ActionTypes.UPDATE_PRODUCT,
  payload,
});

export const deleteProductType = (payload: IProductTypes) => ({
  type: ActionTypes.DELETE_PRODUCT,
  payload,
});