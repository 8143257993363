import { ICustomerListTypes, ICustomersRes } from "./types";

export const ActionTypes = {
  SET_STORE_CUSTOMERS_LIST: "SET_STORE_CUSTOMERS_LIST",
  SET_STORE_CUSTOMERS_DETAILS:"SET_STORE_CUSTOMERS_DETAILS",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",

};

export const setStoreCustomersList = (payload: ICustomerListTypes) => ({
  type: ActionTypes.SET_STORE_CUSTOMERS_LIST,
  payload,
});
export const setStoreCustomersDetails = (payload: ICustomersRes) => ({
  type: ActionTypes.SET_STORE_CUSTOMERS_DETAILS,
  payload,
});