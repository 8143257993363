import { ISizeSpecsRes,ISizeSpecsTypes } from "./types";

export const ActionTypes = {
  SIZE_SPECS_LIST: "SIZE_SPECS_LIST",
  ADD_SIZE_SPECS: "ADD_SIZE_SPECS",
  UPDATE_SIZE_SPECS: "UPDATE_SIZE_SPECS",
  DELETE_SIZE_SPECS: "DELETE_SIZE_SPECS",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",

};

export const setSizeSpecsList = (payload: ISizeSpecsRes) => ({
  type: ActionTypes.SIZE_SPECS_LIST,
  payload,
});

export const addSizeSpecs = (payload: ISizeSpecsTypes) => ({
  type: ActionTypes.ADD_SIZE_SPECS,
  payload,
});

export const updateSizeSpecs = (payload: ISizeSpecsTypes) => ({
  type: ActionTypes.UPDATE_SIZE_SPECS,
  payload,
});

export const deleteSizeSpecs = (payload: ISizeSpecsTypes) => ({
  type: ActionTypes.DELETE_SIZE_SPECS,
  payload,
});