import { IStatusOrder, IPORes, IInquiryTypesRes, IAddInquiryTypes, ISupplierList, IInquirySupplierListRes, ISaleOrderRes, IAddSaleOrderTypes, IInquirySupplierSale, IDeliveredRes, IDetailInquirySale, IEmailTypes, IItemRes, ITaxRateRes, IPOTypes, ITaxRate, IPaymentTermRes } from "./types";

export const ActionTypes = {
  REQUEST_QUOTATION_LIST: "REQUEST_QUOTATION_LIST",
  ADD_REQUEST_QUOTATION: "ADD_REQUEST_QUOTATION",
  UPDATE_REQUEST_QUOTATION: "UPDATE_REQUEST_QUOTATION",
  DELETE_REQUEST_QUOTATION: "DELETE_REQUEST_QUOTATION",
  GET_REQUEST_QUOTATION: "GET_REQUEST_QUOTATION",
  SET_STORE_SUPPLIER_LIST: "SET_STORE_SUPPLIER_LIST",
  SET_STORE_INQUIRY_SUPPLIER_LIST: "SET_STORE_INQUIRY_SUPPLIER_LIST",
  SET_STORE_INQUIRY_DETAILS: "SET_STORE_INQUIRY_DETAILS",
  SET_STORE_INQUIRY_ITEM_DETAILS: "SET_STORE_INQUIRY_ITEM_DETAILS",
  SALE_ORDER_LIST: "SALE_ORDER_LIST",
  ADD_SALE_ORDER_LIST: "ADD_SALE_ORDER_LIST",
  CHANGE_SALE_ORDER_STATUS: "CHANGE_SALE_ORDER_STATUS",
  DELETE_SALE_ORDER: "DELETE_SALE_ORDER",
  PO_LIST: "PO_LIST",
  DELIVERED_LIST: "DELIVERED_LIST",
  UPDATE_DELIVERED_LIST:"UPDATE_DELIVERED_LIST",
  DELIVERED_POPUP_LIST: "DELIVERED_POPUP_LIST",
  SET_STORE_SALE_INQUIRY_DETAILS: "SET_STORE_SALE_INQUIRY_DETAILS",
  SET_STORE_INQUIRY_SUPPLIER_SALE: "SET_STORE_INQUIRY_SUPPLIER_SALE",
  SET_STORE_SCHEDULED_LIST: "SET_STORE_SCHEDULED_LIST",
  DELETE_SCHEDULED_DELIVERY: "DELETE_SCHEDULED_DELIVERY",
  DELETE_BOL: "DELETE_BOL",
  DETAIL_INQUIRY: "DETAIL_INQUIRY",
  EMAIL_DETAIL: "EMAIL_DETAIL",
  SET_INTUIT_LIST: "SET_INTUIT_LIST",
  SET_TAX_LIST: "SET_TAX_LIST",
  SET_TERMS_LIST: "SET_TERMS_LIST",
  ADD_PADLET: "ADD_PADLET",
  SET_STORE_TAX_RATE: "SET_STORE_TAX_RATE",
  SET_STORE_INQUIRY_SALE_DATA: "SET_STORE_INQUIRY_SALE_DATA",

};

export const setStoreTaxRate = (payload?: ITaxRate) => ({
  type: ActionTypes.SET_STORE_TAX_RATE,
  payload: payload,
});
export const setReqQuotationList = (payload: ISaleOrderRes) => ({
  type: ActionTypes.REQUEST_QUOTATION_LIST,
  payload: payload,
});
export const setEmailDetail = (payload: IEmailTypes) => ({
  type: ActionTypes.EMAIL_DETAIL,
  payload: payload,
});

export const setDeliveredList = (payload: IDeliveredRes) => ({
  type: ActionTypes.DELIVERED_LIST,
  payload: payload,
});
export const setUpdateDeliveredList = (payload: IPOTypes) => ({
  type: ActionTypes.UPDATE_DELIVERED_LIST,
  payload: payload,
});
export const setDeliveredPopupList = (payload: ISaleOrderRes) => ({
  type: ActionTypes.REQUEST_QUOTATION_LIST,
  payload: payload,
});
export const deleteScheduledDelivery = (payload: string) => ({
  type: ActionTypes.DELETE_SCHEDULED_DELIVERY,
  payload: payload,
});

export const deleteBOL = (payload: string) => ({
  type: ActionTypes.DELETE_BOL,
  payload: payload,
});
export const setPOList = (payload: IPORes) => ({
  type: ActionTypes.PO_LIST,
  payload: payload,
});

export const setInquiryDetail = (payload: IDetailInquirySale) => ({
  type: ActionTypes.DETAIL_INQUIRY,
  payload: payload,
});
export const setStoreScheduledList = (payload: IPORes) => ({
  type: ActionTypes.SET_STORE_SCHEDULED_LIST,
  payload: payload,
});

export const setSaleOrderList = (payload: IInquiryTypesRes) => ({
  type: ActionTypes.SALE_ORDER_LIST,
  payload: payload,
});

export const addReqQuotation = (payload: IAddInquiryTypes) => ({
  type: ActionTypes.ADD_REQUEST_QUOTATION,
  payload,
});

export const addSaleOrder = (payload: IAddSaleOrderTypes) => ({
  type: ActionTypes.ADD_SALE_ORDER_LIST,
  payload,
});

export const updateReqQuotation = (payload: IAddInquiryTypes) => ({
  type: ActionTypes.UPDATE_REQUEST_QUOTATION,
  payload,
});

export const deleteReqQuotation = (payload: string) => ({
  type: ActionTypes.DELETE_REQUEST_QUOTATION,
  payload,
});

export const getReqQuotation = (payload: IAddInquiryTypes) => ({
  type: ActionTypes.GET_REQUEST_QUOTATION,
  payload,
})
export const setStoreSupplierList = (payload: ISupplierList) => ({
  type: ActionTypes.SET_STORE_SUPPLIER_LIST,
  payload,
});
export const setStoreInquirySupplierList = (payload: IInquirySupplierListRes) => ({
  type: ActionTypes.SET_STORE_INQUIRY_SUPPLIER_LIST,
  payload,
});

export const setStoreInquiryDetails = (payload?: ISupplierList) => ({
  type: ActionTypes.SET_STORE_INQUIRY_DETAILS,
  payload,
});
export const setStoreInquiryItemDetails = (payload?: ISupplierList) => ({
  type: ActionTypes.SET_STORE_INQUIRY_ITEM_DETAILS,
  payload,
});

export const changeSaleOrderStatus = (payload: IStatusOrder) => ({
  type: ActionTypes.CHANGE_SALE_ORDER_STATUS,
  payload,
});

export const deleteSaleOrder = (payload: string) => ({
  type: ActionTypes.DELETE_SALE_ORDER,
  payload,
});

export const setStoreInquirySaleDetails = (payload?: IAddInquiryTypes) => ({
  type: ActionTypes.SET_STORE_SALE_INQUIRY_DETAILS,
  payload,
});


export const setStoreInquirySupplierSale = (payload?: IInquirySupplierSale) => ({
  type: ActionTypes.SET_STORE_INQUIRY_SUPPLIER_SALE,
  payload,
});

export const setIntuitList = (payload?: IItemRes) => ({
  type: ActionTypes.SET_INTUIT_LIST,
  payload,
});

export const setIntuitTaxList = (payload?: ITaxRateRes) => ({
  type: ActionTypes.SET_TAX_LIST,
  payload,
});

export const setIntuitTerms = (payload?: IPaymentTermRes) => ({
  type: ActionTypes.SET_TERMS_LIST,
  payload,
});


export const setAddPadlet = (payload: IPOTypes) => ({
  type: ActionTypes.ADD_PADLET,
  payload: payload,
});

export const setStoreInquirySaleData = (payload?: IAddInquiryTypes) => ({
  type: ActionTypes.SET_STORE_INQUIRY_SALE_DATA,
  payload,
});
