import { IEmailSetupRes } from "./types";

export const ActionTypes = {
  SET_STORE_EMAIL_SETUP_LIST: "SET_STORE_EMAIL_SETUP_LIST",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",

};

export const setStoreEmailSetupList = (payload: IEmailSetupRes) => ({
  type: ActionTypes.SET_STORE_EMAIL_SETUP_LIST,
  payload,
});