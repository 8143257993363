import { IExernalUserTypesRes,IExternalUserTypes } from "./types";

export const ActionTypes = {
  EXTERNAL_USER_LIST: "EXTERNAL_USER_LIST",
  ADD_EXTERNAL_USER: "ADD_EXTERNAL_USER",
  UPDATE_EXTERNAL_USER: "UPDATE_EXTERNAL_USER",
  DELETE_EXTERNAL_USER: "DELETE_EXTERNAL_USER",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",

};


export const setExternalUserList = (payload: IExernalUserTypesRes) => ({
  type: ActionTypes.EXTERNAL_USER_LIST,
  payload,
});

export const addExternalUser = (payload: IExternalUserTypes) => ({
  type: ActionTypes.ADD_EXTERNAL_USER,
  payload,
});

export const updateExternalUser = (payload: IExternalUserTypes) => ({
  type: ActionTypes.UPDATE_EXTERNAL_USER,
  payload,
});

export const deleteExternalUser = (payload: IExternalUserTypes) => ({
  type: ActionTypes.DELETE_EXTERNAL_USER,
  payload,
});