import { IProjectRes,IProjectTypes } from "./types";

export const ActionTypes = {
  PROJECT_LIST: "PROJECT_LIST",
  ADD_PROJECT: "ADD_PROJECT",
  UPDATE_PROJECT: "UPDATE_PROJECT",
  DELETE_PROJECT: "DELETE_PROJECT",
  UPDATE_STATUS_PROJECT: "UPDATE_STATUS_PROJECT",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",
};

export const setProjectTypeList = (payload: IProjectRes) => ({
  type: ActionTypes.PROJECT_LIST,
  payload,
});

export const addProjectType = (payload: IProjectTypes) => ({
  type: ActionTypes.ADD_PROJECT,
  payload,
});

export const updateProjectType = (payload: IProjectTypes) => ({
  type: ActionTypes.UPDATE_PROJECT,
  payload,
});

export const deleteProjectType = (payload: IProjectTypes) => ({
  type: ActionTypes.DELETE_PROJECT,
  payload,
});

export const editStatusProjectType = (payload: IProjectTypes) => ({
  type: ActionTypes.UPDATE_STATUS_PROJECT,
  payload,
});