/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionTypes } from './action';
import { ILogisticLocationServiceRes } from "./types";


const initialState: ILogisticLocationServiceRes = {
  items: [],
  totalCount: 0,
  optionList: []


};

const logisticLocationServiceReducers = (state: ILogisticLocationServiceRes = initialState, action): ILogisticLocationServiceRes => {
  switch (action?.type) {
    case ActionTypes.LOGISTIC_LOCATION_SERVICE_LIST: {
      const items = action?.payload || [];
      const optionList = action?.payload?.map((item: any, index: any) => {
        return {
          value: item.delivery_address,
          label: item.delivery_address,
        }
      })
      return {
        ...state,
        ...action?.payload,
        items: items,
        totalCount: items.length,
        optionList: optionList,
      };

    }
    case ActionTypes.ADMIN_LOGOUT: {
      return initialState
    }
    default:
      return state;
  }
};

export default logisticLocationServiceReducers;
