
import { ActionTypes } from './action';
import { ISupplierRes } from "./types";


const initialState: ISupplierRes = {
    items: [],
    totalCount: 0,

};

const supplierReducers = (state: ISupplierRes = initialState, action): ISupplierRes => {
  switch (action?.type) {
    case ActionTypes.SUPPLIER_LIST:{
      const optionList = action?.payload?.items?.map((item: any, index: any)=>{
        return{
          value: item.id,
          label: item.name,
        }
      })
      return {
        ...state,
        ...action?.payload,
        optionList:optionList
      };
    }
    case ActionTypes.ADD_SUPPLIER:{
      const items = [];
      items.push(action?.payload);
      state?.items?.map((item)=>
        (items.push(item))
      )
      const totalCount = state?.totalCount + 1;
      return {
        ...state,
        items: items,
        totalCount: totalCount,
      };
    }
    case ActionTypes.UPDATE_SUPPLIER:{
      const updatedList = state?.items.map(item =>
        item.id === action?.payload.id ? action?.payload : item
      );
      return {
        ...state,
        items: updatedList,
      };
    }
    case ActionTypes.DELETE_SUPPLIER:{
      const updatedList = state.items.filter(item => item.id !== action.payload.id);
      const totalCount = state?.totalCount - 1;
      return {
        ...state,
        items: updatedList,
        totalCount: totalCount
      };
    }
    default:
      return state;
  }
};

export default supplierReducers;
