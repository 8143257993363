import { IStatusOfDrawingRes,IStatusOfDrawingTypes } from "./types";

export const ActionTypes = {
  STATUS_OF_DRAWING_LIST: "STATUS_OF_DRAWING_LIST",
  ADD_STATUS_OF_DRAWING: "ADD_STATUS_OF_DRAWING",
  UPDATE_STATUS_OF_DRAWING: "UPDATE_STATUS_OF_DRAWING",
  DELETE_STATUS_OF_DRAWING: "DELETE_STATUS_OF_DRAWING",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",

};

export const setStatusOfDrawingList = (payload: IStatusOfDrawingRes) => ({
  type: ActionTypes.STATUS_OF_DRAWING_LIST,
  payload,
});

export const addStatusOfDrawing = (payload: IStatusOfDrawingTypes) => ({
  type: ActionTypes.ADD_STATUS_OF_DRAWING,
  payload,
});

export const updateStatusOfDrawing = (payload: IStatusOfDrawingTypes) => ({
  type: ActionTypes.UPDATE_STATUS_OF_DRAWING,
  payload,
});

export const deleteStatusOfDrawing = (payload: IStatusOfDrawingTypes) => ({
  type: ActionTypes.DELETE_STATUS_OF_DRAWING,
  payload,
});