import { IScopeOfWorkRes,IScopeOfWorkTypes } from "./types";

export const ActionTypes = {
  SCOPE_OF_WORK_LIST: "SCOPE_OF_WORK_LIST",
  ADD_SCOPE_OF_WORK: "ADD_SCOPE_OF_WORK",
  UPDATE_SCOPE_OF_WORK: "UPDATE_SCOPE_OF_WORK",
  DELETE_SCOPE_OF_WORK: "DELETE_SCOPE_OF_WORK",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",

};

export const setScopeOfWorkTypeList = (payload: IScopeOfWorkRes) => ({
  type: ActionTypes.SCOPE_OF_WORK_LIST,
  payload,
});

export const addScopeOfWorkType = (payload: IScopeOfWorkTypes) => ({
  type: ActionTypes.ADD_SCOPE_OF_WORK,
  payload,
});

export const updateScopeOfWorkType = (payload: IScopeOfWorkTypes) => ({
  type: ActionTypes.UPDATE_SCOPE_OF_WORK,
  payload,
});

export const deleteScopeOfWorkType = (payload: IScopeOfWorkTypes) => ({
  type: ActionTypes.DELETE_SCOPE_OF_WORK,
  payload,
});