import { ISupplierRes,ISupplierTypes } from "./types";

export const ActionTypes = {
  SUPPLIER_LIST: "SUPPLIER_LIST",
  ADD_SUPPLIER: "ADD_SUPPLIER",
  UPDATE_SUPPLIER: "UPDATE_SUPPLIER",
  DELETE_SUPPLIER: "DELETE_SUPPLIER",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",

};

export const setSupplierList = (payload: ISupplierRes) => ({
  type: ActionTypes.SUPPLIER_LIST,
  payload,
});

export const addSupplier = (payload: ISupplierTypes) => ({
  type: ActionTypes.ADD_SUPPLIER,
  payload,
});

export const updateSupplier = (payload: ISupplierTypes) => ({
  type: ActionTypes.UPDATE_SUPPLIER,
  payload,
});

export const deleteSupplier = (payload: ISupplierTypes) => ({
  type: ActionTypes.DELETE_SUPPLIER,
  payload,
});