
import { ActionTypes } from "./action";
import { IDashboardData } from "./types";


const initialState: IDashboardData = {
  inventoryStocks: [],
  LogisticsSummary: [],
  InventoryChanges: {
    date: ""
  },
  StockPercentages: {
    inStockPercentage: 0,
    lowStockPercentage: 0,
    outOfStockPercentage: 0
  },
  ProposalCounts: {
    date: ""
  }
};

const dashboardReducers = (state: IDashboardData = initialState, action):IDashboardData  => {
  switch (action.type) {
    case ActionTypes.SET_DASHBOARD_DATA:
      return {
        ...state,
        inventoryStocks: action?.payload?.inventoryStocks,
        LogisticsSummary:  Object.entries(action?.payload?.LogisticsSummary).map(([key, value]) => ({
          name: key,
          value: value
        })),
        InventoryChanges: action?.payload?.InventoryChanges,
        StockPercentages: action?.payload?.StockPercentages,
        ProposalCounts: action?.payload?.ProposalCounts,
      };
  
    default:
      return state;
  }  
};

export default dashboardReducers;
